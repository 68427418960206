// components/Deductions-Loader.jsx
import { generateClient } from 'aws-amplify/api';
import { getSFTPImportDate } from '../graphql/queries';

const client = generateClient();

export const loader = async () => {
    try {
        const { data: sftpImportDateData } = await client.graphql({ query: getSFTPImportDate });
        return {
            sftpImportDateData: sftpImportDateData.getSFTPImportDate.SFTPImportDate,
        };

    } catch (error) {
        console.error('Error loading data:', error);
        // Handle the error or rethrow it based on your requirements
        throw error;
    }
};
