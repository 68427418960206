/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSSMParameters = /* GraphQL */ `
  query GetSSMParameters($input: BySSMParametersNames!) {
    getSSMParameters(input: $input) {
      Name
      Value
      __typename
    }
  }
`;
export const getSFTPFile = /* GraphQL */ `
  query GetSFTPFile($input: ByDateInput!) {
    getSFTPFile(input: $input) {
      Key
      Body
      __typename
    }
  }
`;
export const getSFTPImportDate = /* GraphQL */ `
  query GetSFTPImportDate {
    getSFTPImportDate {
      SFTPImportDate
      __typename
    }
  }
`;
export const queryAllDeductions = /* GraphQL */ `
  query QueryAllDeductions {
    queryAllDeductions {
      SubmissionDate
      EmployeeNumber
      PayrollPeriodEndDate
      Amount
      __typename
    }
  }
`;
export const queryDeductionsByDate = /* GraphQL */ `
  query QueryDeductionsByDate($input: ByDateInput!) {
    queryDeductionsByDate(input: $input) {
      SubmissionDate
      EmployeeNumber
      PayrollPeriodEndDate
      Amount
      __typename
    }
  }
`;
