import React, { useState } from 'react';
import { sendMessageToLex } from './lex/lexUtils';
import { createCustomMessage } from "react-chatbot-kit";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

    const [lexData, setLexData] = useState(null);

    const handlerButtonToLex = async (textFromOption) => {
        const lexResponse = await sendMessageToLex(textFromOption);
        handlerAnything(lexResponse);
    };

    const handlerAnything = (lexResponse) => {
        setLexData(lexResponse);
        const lexState = lexResponse?.sessionState?.intent.state ?? null;
    
        lexResponse.messages.forEach((msg, index) => {
            const { content, imageResponseCard } = msg;
            const title = content || imageResponseCard?.title;
            const widget = lexState === "InProgress" ? "deductions" : undefined;
            const payload = { msgIndex: index };

            // // Create Custom Message for Box Slots
            if(widget){
                setTimeout(() => {
                    const customMessage = createCustomMessage(title, "quota");
                    addMessageToState(customMessage);
                }, index * 1000); // Here, '1000' represents 1000 milliseconds (1 second). You can adjust the delay as per your requirement.
            };

            setTimeout(() => {
                // const message = createChatBotMessage(title, { widget, payload });
                const message = createChatBotMessage(title, { widget, payload });
                addMessageToState(message);
            }, index * 1000); // Here, '1000' represents 1000 milliseconds (1 second). You can adjust the delay as per your requirement.
        });
    };

    const addMessageToState = (message) => {
        setState(prevState => ({ 
            ...prevState,
            messages: [...prevState.messages, message],
        }));
    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        handlerButtonToLex,
                        createChatBotMessage,
                        addMessageToState,
                        handlerAnything,
                        lexData, // Pass lexData state
                        setLexData, // Pass setLexData function
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;