import React from 'react';

export const useValidateRow = () => {
    return React.useCallback(
      (row) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            if (row.EmployeeNumber?.trim().length === 6) {
              if (row.PayrollPeriodEndDate?.trim().length === 10) {
                if (row.Amount != null && row.Amount !== "") {
                  resolve(null);
                } else {
                  reject(new Error("Please enter valid Amount."));
                }
              } else {
                // resolve({ ...row, EmployeeNumber: row.EmployeeNumber?.toUpperCase() });
                reject(new Error("Please enter valid Payroll Period End Date."));
              }
            } else {
              // resolve({ ...row, EmployeeNumber: row.EmployeeNumber?.toUpperCase() });
              reject(new Error("Please enter 6-digit Employee Number."));
            }
          }, 200);
        }),
      [],
    );
  };