import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const WelcomeOptions = (props) => {
    const [selectedOptionId, setSelectedOptionId] = useState(null);
    const [disabled, setDisabled] = useState(false);

    const options = [
        {
            text: "Create a Deduction",
            handler: props.actionProvider.handlerButtonToLex,
            id: 1
        },
        {
            text: "Delete a Deduction",
            handler: props.actionProvider.handlerButtonToLex,
            id: 2
        },
        {
            text: "Query Deductions",
            handler: () => {},
            id: 3
        },
    ];

    // Function to handle the button click
    const handleButtonClick = (option) => {
        option.handler(option.text);
        setSelectedOptionId(option.id);
        setDisabled(true);
    };
    
    const buttonsMarkup = options.map((option) => (
        <Button
            key={option.id}
            onClick={() => handleButtonClick(option)}
            variant={selectedOptionId === option.id ? "contained" : "outlined"} // Change variant based on selectedOptionId
            disabled={disabled}
        >
            {option.text}
        </Button>
    ));

    return <Stack spacing={1}>{buttonsMarkup}</Stack>
}

export default WelcomeOptions;