import { useState, useMemo } from 'react';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const Deductions = (props) => {

    // Set Buttons states
    const [selectedOptionId, setSelectedOptionId] = useState(null);
    const [disabled, setDisabled] = useState(false);

    // Set Slots variables for Slots Box
    // const slots = props.actions.lexData?.sessionState?.intent.slots ?? null;
    // const employeeNumber = slots.EmployeeNumber?.value?.interpretedValue ?? null;
    // const amount = slots.Amount?.value?.interpretedValue ?? null;

    // Set Active Message variables
    const msgIndex = props.payload.msgIndex;
    const theMessageImageResponseCardButtons = props.actions.lexData?.messages[msgIndex]?.imageResponseCard?.buttons ?? null;

    // Format Currency
    // const formattedCurrency = parseFloat(amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

    let optionsButtons = [];
    if ( theMessageImageResponseCardButtons ) {
        
        // Loop through the buttons array and access each button
        theMessageImageResponseCardButtons.forEach((button, index) => {
            
            // Access the button properties here
            // const buttonValue = button?.value ?? null;
            const buttonText = button?.text ?? null;

            // Use the button properties as needed
            const newButton = {
                text: buttonText,
                handler: props.actionProvider.handlerButtonToLex,
                id: index + 1, // Assuming you want the button index as the id
            };
            
            // Push the new button object to the optionButtons array
            optionsButtons.push(newButton);
        });
    }

    // Function to handle the button click
    const handleButtonClick = (option) => {
        option.handler(option.text);
        setSelectedOptionId(option.id);
        setDisabled(true);
    };
    
    const buttonsMarkup = useMemo(() =>
        optionsButtons.map((option) => (
            <Button
                key={option.id}
                onClick={() => handleButtonClick(option)}
                variant={selectedOptionId === option.id ? "contained" : "outlined"} // Change variant based on selectedOptionId
                disabled={disabled}
            >
                {option.text}
            </Button>
        ),
    ), [disabled]);

    // Use React.useMemo to memoize the Box component
    const boxComponent = useMemo(() => {
        return (
            <>
                {/* {(employeeNumber || amount) && (
                    <Box
                        className="botSlots"
                    >
                    {employeeNumber && (
                            <Typography>
                                Employee Number: {employeeNumber}
                            </Typography>

                    )}
                    {amount && (
                            <Typography>
                                Amount: {formattedCurrency}
                            </Typography>
                    )}
                    </Box>
                )} */}
                {buttonsMarkup.length > 0 && (
                    <>
                        {/* <br /> */}
                        <Stack spacing={1} >
                            {buttonsMarkup}
                        </Stack>
                    </>
                )}
            </>
        );
    }, [disabled]); // Empty dependency array ensures the component is created only once

    return (
        <div>
            {boxComponent}
        </div>
    );
}

export default Deductions;