// RequireAuth.js
import { Outlet } from "react-router-dom";
import { redirect } from "react-router-dom";
import { fetchAuthSession } from 'aws-amplify/auth';
// import { useNavigate } from 'react-router-dom';

export const dataLoader = async () => {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    if (!accessToken) {
        // navigate('/login', { state: { from: { pathname: pathname} } });
        // navigate('/login', );
        throw redirect("/login");
    }
    return null;
};

export default function RequireAuth() {
    return <Outlet />;
};