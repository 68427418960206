import { Image, View, useTheme } from "@aws-amplify/ui-react";

export function Header() {

  const { tokens } = useTheme();
  
  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Image
        alt="PEBS logo"
        src="https://pebs-cloud-mgmt.s3.amazonaws.com/pebs_logo.png"
      />
    </View>
  );
}
