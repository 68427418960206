import { useEffect, useState } from 'react';
// import { Auth } from 'aws-amplify';
import { getCurrentUser } from 'aws-amplify/auth';
import Box from '@mui/material/Box';
// import { createChatBotMessage, createCustomMessage } from 'react-chatbot-kit';
import { createChatBotMessage } from 'react-chatbot-kit';
import { ReactComponent as SugiIcon } from '../icons/sugi.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { Avatar } from '@mui/material';
import WelcomeOptions from './widgets/WelcomeOptions';
import Deductions from './widgets/Deductions';
import CustomMessage from './CustomMessage';
import QuotaMessage from './QuotaMessage';

const UsernameAvatar = () => {
    const [username, setUsername] = useState(null);
  
    useEffect(() => {
        // Function to fetch the current authenticated user
        const getCurrentAuthenticatedUser = async () => {
            try {
                // const user = await Auth.currentAuthenticatedUser();
                // const { username, userId, signInDetails } = await getCurrentUser();
                const currentUser = await getCurrentUser();
                console.log("currentUser: ", currentUser);
                // // setUsername(user.attributes.email);
                setUsername("sean");
            } catch (error) {
            setUsername(null);
            }
        };
        getCurrentAuthenticatedUser();
    }, []);

    return (
        <Avatar variant="user">
            {username ? username.charAt(0).toUpperCase() : "N"}
        </Avatar>
    );
};

const botName = 'Sugi';

const initialMessages = [
    `Hi! I'm ${botName}. How may I assist you today?`,
    `Hello there! I'm ${botName}. How can I help you?`,
    `Greetings! I'm ${botName}. What can I do for you?`,
    `Hey! I'm ${botName}. How may I be of service?`,
    `Hi there! I'm ${botName}. What do you need help with?`,
    `Hello! I'm ${botName}. How can I assist you today?`,
    `Good day! I'm ${botName}. What can I do to help?`,
];
const randomIndex = Math.floor(Math.random() * initialMessages.length);
const randomInitialMessage = initialMessages[randomIndex];

const config = {
    initialMessages: [
        createChatBotMessage(
            randomInitialMessage, {
                widget: "welcomeOptions",
            }
        ),
        // createCustomMessage(randomInitialMessage, 'custom', {
        //     widget: "options",
        // }),
    ],
    customComponents: {
        // Replaces the default header
        header: () =>
            <Box
                sx={{
                    bgcolor: 'secondary.main',
                    color: 'primary.main',
                    padding: "12.5px",
                    borderRadius: "3px",
                    fontWeight: 'fontWeightBold',
                    zIndex: 'tooltip',
                }}
                variant="bot"
            >
                Sugi from Cedar
            </Box>,
        botAvatar: (props) =>
            <Avatar
                variant="bot"
            >
                <SvgIcon
                    component={SugiIcon}
                />
            </Avatar>,
        userAvatar: (props) => <UsernameAvatar />
    },
    customMessages: {
        custom: (props) => <CustomMessage {...props} />,
        quota: (props) => <QuotaMessage {...props} />,
    },
     widgets: [
        {
            widgetName: 'deductions',
            widgetFunc: (props) => <Deductions {...props} />,
        },
        {
            widgetName: 'welcomeOptions',
            widgetFunc: (props) => <WelcomeOptions {...props} />,
        },
     ]
};

export default config;