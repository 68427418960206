import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
import '@aws-amplify/ui-react/styles.css'
import { ThemeProvider } from '@mui/material/styles';
import pebsTheme from './theme';
import 'material-icons/iconfont/material-icons.css';
import LoadingScreen from './components/Elements/LoadingScreen';

Amplify.configure(amplifyconfig);

const Root = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data, initializing app)
    const fetchData = async () => {
      // Your asynchronous operations go here

      // Simulate a delay (remove this in your actual implementation)
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Set loading to false once your operations are done
      setLoading(false);
    };

    fetchData();
  }, []);

  return loading ? <LoadingScreen /> : (
    <ThemeProvider theme={pebsTheme}>
      <App />
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);
