import React, { useState } from 'react';
import { Tab, Tabs, AppBar, Grid, Toolbar, IconButton, Typography, Box, Modal } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { Icon} from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Fab from '@mui/material/Fab';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as SugiIcon } from '../icons/sugi.svg';
import Chatbot from 'react-chatbot-kit'
import config from '../chatbot/config.js';
import messageParser from '../chatbot/MessageParser.js';
import actionProvider from '../chatbot/ActionProvider.js';
import { Outlet } from "react-router-dom";
import { useNavigation } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';

const Logo = styled('img')(({ theme }) => ({
    width: theme.logo.width,
    height: theme.logo.height,
}));

const drawerWidth = 240;
const navItems = [
    { text: 'Deductions', target: '/deductions', icon: 'home', divider: true, showUnauth: false },
    { text: 'History', target: '/history', icon: 'history', showUnauth: false },
];

const styleChatbot = {
    position: 'fixed',
    right: '25px',
    bottom: '95px',
    boxShadow: 24,
    padding: 0
};

export function Root() {

    const navigation = useNavigation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [openFab, setOpenFab] = React.useState(false);
    const handleOpenFab = () => setOpenFab(true);
    const handleCloseFab = () => setOpenFab(false);
    const [mobileOpen, setMobileOpen] = useState(false);

    const theme = useTheme();
    // const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const appBarHeight = isMobile ? theme.mixins.toolbar.minHeight : theme.mixins.toolbar.minHeight + 8;
    const appBarHeightPlus = isMobile ? 22 : 30;

    // const { route, signOut } = useAuthenticator((context) => [
    const { signOut } = useAuthenticator((context) => [
        // context.route,
        context.signOut,
    ]);
    const navigate = useNavigate();

    const location = useLocation();
    let pathname = location.pathname;
    if(pathname === "/") {
        pathname = "/deductions";
    }

    // Check Whether authenticated
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);

    // const [currentTab, setCurrentTab] = React.useState(0);
    const [currentTab, setCurrentTab] = React.useState(pathname);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography
                // variant="h6"
                sx={{
                    // Set height of Typography equal the height of the AppBAr
                    height: `${theme.mixins.toolbar.minHeight}px`,
                    display: 'flex',
                    alignItems: 'center',
                    pl: 2, // Adjust padding-left
                }}
            >
                {/* Billy vs Elton */}
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <React.Fragment key={item.target}>
                        <ListItemButton
                            key={item.target}
                            selected={currentTab === item.target}
                            component={RouterLink}
                            onClick={() => setCurrentTab(item.target)}
                            to={item.target}
                        >
                            {item.icon && (
                                <ListItemIcon>
                                    <Icon>{item.icon}</Icon>
                                </ListItemIcon>
                            )}
                            <ListItemText
                                primary={item.text}
                            />
                        </ListItemButton>
                        {item.divider && (
                            <Divider />
                        )}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );

    function logOut() {
        signOut();
        navigate('/login', { state: { from: { pathname: pathname} } });
        // throw redirect("login");
    }

    const handleChange = (event, value) => {
        setCurrentTab(value);
    };

    const renderLogout = () => {
        return (
            <Grid item>
            <IconButton
                size="large"
                edge="end"
                color="primary"
                aria-label="logout"
                onClick={() => logOut()}
            >
                {/* <LogoutIcon /> */}
                <Icon>logout</Icon>  
            </IconButton>
            </Grid> 
        );
    }

    const handleMenuItemClick = (value) => {
        setCurrentTab(value);
        setAnchorEl(null);
    };

    const renderMenu = () => {
        return (
            <Grid xs={1} item>
                <IconButton
                    size="large"
                    edge="start"
                    color="primary"
                    aria-label="open drawer"
                    sx={{ mr:  0 }}
                    onClick={handleDrawerToggle}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        component={RouterLink}
                        to="/deductions"
                        onClick={() => handleMenuItemClick("/deductions")}
                        selected={currentTab === "/deductions"}
                    >
                        Deductions
                    </MenuItem>
                    <MenuItem 
                        component={RouterLink}
                        to="/history"
                        onClick={() => handleMenuItemClick("/history")}
                        selected={currentTab === "/history"}
                    >
                        History
                    </MenuItem>
                </Menu>
            </Grid>
        );
    }

    const renderLogoAndTypography = () => {
        return (
            <Grid sx={{ justifyContent: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Logo
                        src={
                            "https://pebs-cloud-mgmt.s3.amazonaws.com/pebs_logo.png"
                        }
                        alt="PEBS Logo Logo"
                    />
                </div>
            </Grid>
        );
    }

    const renderTabs = () => {
        return (
            <Grid xs={7} item>
                <Tabs
                    onChange={handleChange}
                    value={currentTab}
                    aria-label="Navigation Tabs"
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                >
                    <Tab label="Deductions" value="/deductions" component={RouterLink} to="/deductions" />
                    <Tab label="History" value="/history" component={RouterLink} to="/history" />
                </Tabs>
            </Grid>
        );
    }

    return (
        <>
            {/* {route === 'authenticated' ? ( */}
            {authStatus === 'authenticated' ? (
                <>
                    {isMobile ? (
                        <React.Fragment>
                            <AppBar
                                position="sticky"  // Add this line to make the app bar fixed
                                component="nav"
                                // position="static"
                                sx={{ backgroundColor: theme.palette.secondary.main}}
                            >
                                <Toolbar>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                    >
                                        {renderMenu()}
                                        {renderLogoAndTypography()}
                                        {renderLogout()}
                                    </Grid>
                                </Toolbar>
                            </AppBar>
                            <nav>
                                <Drawer
                                    // container={container}
                                    variant="temporary"
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                    sx={{
                                        display: { xs: 'block', sm: 'none' },
                                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                    }}
                                >
                                    {drawer}
                                </Drawer>
                            </nav>
                        </React.Fragment>
                    ) : (
                        <AppBar
                            // position="static"
                            position="sticky"  // Add this line to make the app bar fixed
                            sx={{ backgroundColor: theme.palette.secondary.main}}
                        >
                            <Toolbar>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    wrap='nowrap'
                                >
                                    {renderLogoAndTypography()}
                                    {renderTabs()}
                                    {renderLogout()}
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    )}
                    {navigation.state === "loading" && <LinearProgress style={{ position: 'fixed', top: `${appBarHeight}px`, width: '100%', zIndex: 9999 }} />}
                    <div style={{ marginTop: `${appBarHeightPlus}px` }}>
                        <Outlet />
                    </div>
                    <Modal
                        open={openFab}
                        onClose={handleCloseFab}
                    >
                        <Box sx={styleChatbot}>
                            <Chatbot
                                config={config}
                                actionProvider={actionProvider}
                                // messageHistory={loadMessages()}
                                messageParser={messageParser}
                                // saveMessages={saveMessages}
                                placeholderText='Compose your message...'
                            />
                        </Box>
                    </Modal>
                    <Fab
                        color="primary"
                        aria-label="add"
                        onClick={handleOpenFab}
                    >
                        <SvgIcon sx={{ fontSize: 30 }} >
                        <SugiIcon />
                        </SvgIcon>
                    </Fab>
                </>
            ) : (
                <div >
                    <Outlet />
                </div>
            )}
        </>
    );
}