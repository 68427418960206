import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const QuotaMessage = (props) => {

    // Set Slots variables for Slots Box
    const slots = props.actions.lexData?.sessionState?.intent.slots ?? null;
    const employeeNumber = slots.EmployeeNumber?.value?.interpretedValue ?? null;
    const amount = slots.Amount?.value?.interpretedValue ?? null;

    // Format Currency
    const formattedCurrency = parseFloat(amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

    // const lastMessage = props.state.messages.length - 1;
    // const message = props.state.messages[lastMessage].message;
    // Use React.useMemo to memoize the Box component
    const boxComponent = useMemo(() => {
        return (
            <>
                {(employeeNumber || amount) && (
                    <Box
                        className="botSlots"
                    >
                    {employeeNumber && (
                            <Typography>
                                Employee Number: {employeeNumber}
                            </Typography>

                    )}
                    {amount && (
                            <Typography>
                                Amount: {formattedCurrency}
                            </Typography>
                    )}
                    </Box>
                )}
                {/* {buttonsMarkup.length > 0 && (
                    <>
                        <br />
                        <Stack spacing={1} >
                            {buttonsMarkup}
                        </Stack>
                    </>
                )} */}
            </>
        );
    }, []); // Empty dependency array ensures the component is created only once

    return (
        <div>
            {boxComponent}
        </div>
    );
};

export default QuotaMessage;