/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDeduction = /* GraphQL */ `
  subscription OnCreateDeduction {
    onCreateDeduction {
      SubmissionDate
      EmployeeNumber
      PayrollPeriodEndDate
      Amount
      __typename
    }
  }
`;
export const onDeleteDeduction = /* GraphQL */ `
  subscription OnDeleteDeduction {
    onDeleteDeduction {
      SubmissionDate
      EmployeeNumber
      PayrollPeriodEndDate
      Amount
      __typename
    }
  }
`;
export const onCreateSFTPFile = /* GraphQL */ `
  subscription OnCreateSFTPFile {
    onCreateSFTPFile {
      Key
      Body
      __typename
    }
  }
`;
export const onUpdateSFTPImportDate = /* GraphQL */ `
  subscription OnUpdateSFTPImportDate {
    onUpdateSFTPImportDate {
      SFTPImportDate
      __typename
    }
  }
`;
