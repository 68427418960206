//App.js
import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import RequireAuth, { dataLoader as dlRequireAuth } from './RequireAuth';
import { Login } from './components/Login';
import { Root } from './components/Root';
import ErrorPage from './error-page';
import { Component as DeductionsComponent } from './components/Deductions-Component';
import { loader as DeductionsLoader } from './components/Deductions-Loader';
import './App.css';
import 'react-chatbot-kit/build/main.css'
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                element: <RequireAuth />,
                loader: dlRequireAuth,
                children: [
                    {
                        index: true,
                        element: <Navigate to="deductions" />,
                    },
                    {
                        path: "/deductions",
                        element: <DeductionsComponent />,
                        loader: () => DeductionsLoader(),
                    },
                    {
                        path: "/deductions/:submissionDate",
                        element: <DeductionsComponent />,
                        loader: () => DeductionsLoader(),
                    },
                    {
                        path: 'history',
                        async loader() {
                            let { loader } = await import("./components/History-Loader");
                            return loader();
                        },
                        lazy: () => import("./components/History-Component"),
                    },
                ],
            },
            { path: "/login", element: <Login />, },
      ],
  },
]);

export default function App() {
  return (
      <Authenticator.Provider>
          <RouterProvider router={router} />
      </Authenticator.Provider>
  );
}

