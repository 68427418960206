// LoadingScreen.jsx
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingScreen = () => {

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                // backgroundColor: '#f0f0f0', // Set your desired background color
            }}
        >
            <CircularProgress
                size={100}
                style={{
                    color: '#1B4985', // Set your desired color
                }}
            />
        </div>
    );
};

export default LoadingScreen;