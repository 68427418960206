import { LexRuntimeV2Client, RecognizeTextCommand } from "@aws-sdk/client-lex-runtime-v2";
import awsconfig from '../../aws-exports'
// import { Auth } from 'aws-amplify';
// import { API, graphqlOperation } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import { getSSMParameters } from "../../graphql/queries";
import { Amplify } from 'aws-amplify';
import amplifyconfig from '../../amplifyconfiguration.json';
import { fetchAuthSession } from 'aws-amplify/auth';

const client = generateClient();

Amplify.configure(amplifyconfig);

// Set SSM Param Values for checking Environment
const COGNITO_DEV_USER_POOL_ID_PARAM = "/cognito/dev/UserPoolId";
const COGNITO_PROD_USER_POOL_ID_PARAM = "/cognito/prod/UserPoolId";
// Set Lex Locale ID
const LEX_LOCALE_ID = "en_US"

const getEnvironmentAbbreviation = (awsConfigUserPoolId, ssmValueParamUserPoolIdProd, ssmValueParamUserPoolIdDev) => {
    switch (awsConfigUserPoolId) {
        case ssmValueParamUserPoolIdProd:
            return "prod";
        case ssmValueParamUserPoolIdDev:
            return "dev";
        default:
            console.log("Default case - Environment not recognized");
            return "dev";
    }
};

// Function to send a message to Lex and get the formatted messages from the response
export async function sendMessageToLex(message) {

    // Set Credentials, IdentityId, and Region
    // const currentCredentials = await Auth.currentCredentials();
    // const identityId = currentCredentials.identityId;
    // const lexRegion = awsconfig.aws_project_region;

    // Set Input for retrieving SSM Parameters
    const inputSSMParams = {
        input: {
          Names: [COGNITO_DEV_USER_POOL_ID_PARAM, COGNITO_PROD_USER_POOL_ID_PARAM]
        }
    };

    // Try Getting SSM Parameters
    try {
        // const { data: { getSSMParameters: ssmParamValues } } = await API.graphql(graphqlOperation(getSSMParameters, inputSSMParams));
        const { data: { getSSMParameters: ssmParamValues } }  = await client.graphql({ query: getSSMParameters, variables: inputSSMParams, });
        const ssmValueParamUserPoolIdDev = ssmParamValues.find((item) => item.Name === COGNITO_DEV_USER_POOL_ID_PARAM)?.Value;
        const ssmValueParamUserPoolIdProd = ssmParamValues.find((item) => item.Name === COGNITO_PROD_USER_POOL_ID_PARAM)?.Value;

        // Set the Current User Pool Id
        const awsConfigUserPoolId = awsconfig.aws_user_pools_id;

        // Check Environment and Set Environment Abbreviation
        const amplifyEnvAbbrev = getEnvironmentAbbreviation(awsConfigUserPoolId, ssmValueParamUserPoolIdProd, ssmValueParamUserPoolIdDev);

        // Set Variables for SSM Parameter for Bot Alias ID and Bot Id
        const ssmParamSugiBotAliasId = `/lex/${amplifyEnvAbbrev}/SugiBotAliasId`;
        const inputSSMParamSugiBotAliasId = {
            input: {
                Names: [ssmParamSugiBotAliasId]
            }
        };
    
        // Try Getting SSM Parameter for Bot Alias ID and Bot Id
        try {
            // const { data: { getSSMParameters: ssmParamValuesSugiBotAliasId } } = await API.graphql(graphqlOperation(getSSMParameters, inputSSMParamSugiBotAliasId));
            const { data: { getSSMParameters: ssmParamValuesSugiBotAliasId } }  = await client.graphql({ query: getSSMParameters, variables: inputSSMParamSugiBotAliasId, });
            const botAliasId = ssmParamValuesSugiBotAliasId.find((item) => item.Name === ssmParamSugiBotAliasId)?.Value;
            const [sugiBotAliasId, sugiBotId] = botAliasId ? botAliasId.split('|') : [null, null];

            // // Initialize Lex Client
            // const client = new LexRuntimeV2Client({ 
            //     region: lexRegion,
            //     credentials: currentCredentials,
            // });

            const session = await fetchAuthSession();

            // Initialize Lex Client
            const createLexClient = async () => {
                console.log("session: ", session)
                const client = new LexRuntimeV2Client({ 
                    // region: lexRegion,
                    credentials: session.credentials,
                    region: amplifyconfig.aws_project_region,
                });
                return client;
            };

            //initialize the Lex client:
            const lexClient = await createLexClient();

            // Set Input for Lex
            const input = {
                botId: sugiBotId,
                botAliasId: sugiBotAliasId,
                localeId: LEX_LOCALE_ID,
                // sessionId: identityId,
                sessionId: session.identityId,
                text: message,
            };
            const command = new RecognizeTextCommand(input);

            // Try Sending Command to Lex
            try {
                // const lexResponse = await client.send(command);
                const lexResponse = await lexClient.send(command);
                return lexResponse;
            } catch (error) {
                console.error("Error sending message to Lex:", error);
                return [];
            }

        // Catch getting SSM Parameters
        } catch (error) {
            console.log("Error getting SSM Param for Lex:", error);
            return [];
        }

    // Catch getting SSM Parameters
    } catch (error) {
        console.log("Error getting SSM Param for Lex:", error);
        return [];
    }
}
