import React, { useMemo } from 'react';
import { ReactComponent as SugiIcon } from '../icons/sugi.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { Avatar, Box } from '@mui/material';

const CustomMessage = (props) => {

    // console.log("props from CustomMessage: ", props);
    const lastMessage = props.state.messages.length - 1;
    const message = props.state.messages[lastMessage].message;
    const memoizedMessage = useMemo(() => {
        return (
            <>
                <div
                    className="react-chatbot-kit-chat-bot-message-container"
                    
                >
                    <Avatar
                        variant="bot"
                    >
                        <SvgIcon
                            component={SugiIcon}
                        />
                    </Avatar>
                    <div 
                        className="react-chatbot-kit-chat-bot-message"
                    >
                        <span>{message}</span>
                        <div className="react-chatbot-kit-chat-bot-message-arrow" />
                    </div>
                </div>
            </>
        );
    }, []);

    return memoizedMessage;

};

export default CustomMessage;