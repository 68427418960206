import React from 'react';
import { sendMessageToLex } from './lex/lexUtils';

const MessageParser = ({ children, actions }) => {
    const parse = async (message) => {

        if (message === null || /^ *$/.test(message)) {
            return;
        }
        const formattedMessages = await sendMessageToLex(message);
        actions.handlerAnything(formattedMessages);
    };



    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    parse: parse,
                    actions,
                });
            })}
        </div>
    );
};

export default MessageParser;