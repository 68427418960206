import { Flex, useTheme } from "@aws-amplify/ui-react";
import { Typography } from '@mui/material';


export function Footer() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={tokens.space.medium}>
      <Typography
        variant="subtitle2"
      >
        Made in West Chester, PA
      </Typography>
    </Flex>
  );
}
