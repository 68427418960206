// components/Deductions-Component.jsx
import React, { useState, useEffect, useRef } from 'react';
import { generateClient } from 'aws-amplify/api';
import { Paper, Typography, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import './../App.css';
import { queryDeductionsByDate, getSFTPFile } from '../graphql/queries';
import { createDeduction, deleteDeduction } from '../graphql/mutations';
import { onCreateDeduction, onDeleteDeduction, onCreateSFTPFile, onUpdateSFTPImportDate } from '../graphql/subscriptions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DataGrid } from '@mui/x-data-grid';
import {
    GridRowModes,
    GridToolbarContainer,
    GridActionsCellItem,
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import { useLoaderData } from "react-router-dom";
import { useValidateRow } from './Hooks/useValidateRow'; 
import { CustomNoRowsOverlay } from './Elements/CustomNoRowsOverlay';

let nextId = 1;
function generateId() {
  return nextId++;
}

const client = generateClient();

export function Component() {

    const [fileContent, setFileContent] = useState('');
    const validateRow = useValidateRow();
    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [rows, setRows] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [dateFromSSM, setDateFromSSM] = useState(null);
    const selectedDateRef = useRef(null);
    
    const { submissionDate } = useParams();
    const { sftpImportDateData } = useLoaderData();

    useEffect(() => {
        setDateFromSSM(dayjs(sftpImportDateData));
        if (submissionDate) {
            handleDateChange(dayjs(submissionDate));
        } else {
            handleDateChange(dayjs(sftpImportDateData));
        }
    }, [sftpImportDateData, submissionDate]);

  useEffect(() => {
    // const subscription = API.graphql(graphqlOperation(onCreateDeduction)).subscribe({
    const subscription = client.graphql({ query: onCreateDeduction }).subscribe({
      next: (result) => {

        const input = {
          input: {
            SubmissionDate: selectedDateRef.current.format("YYYY-MM-DD")
          }
        };

        try {
    
          // API.graphql(graphqlOperation(queryDeductionsByDate, input))
          client.graphql({ query: queryDeductionsByDate, variables: input })
          .then((result) => {
            const retrievedDeductions = result.data.queryDeductionsByDate;
            if (retrievedDeductions) {
              const deductionsWithId = retrievedDeductions.map((deduction) => ({
                ...deduction,
                id: generateId(),
              }));
              setRows(deductionsWithId);
            }
          });

        } catch (error) {
          console.log("error: ", error);
        }
  
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    // const subscription = API.graphql(graphqlOperation(onDeleteDeduction)).subscribe({
    const subscription = client.graphql({ query: onDeleteDeduction }).subscribe({
      next: (result) => {

        const input = {
          input: {
            SubmissionDate: selectedDateRef.current.format("YYYY-MM-DD")
          }
        };

        try {
    
          // API.graphql(graphqlOperation(queryDeductionsByDate, input))
          client.graphql({ query: queryDeductionsByDate, variables: input })
          .then((result) => {
            const retrievedDeductions = result.data.queryDeductionsByDate;
            if (retrievedDeductions) {
              const deductionsWithId = retrievedDeductions.map((deduction) => ({
                ...deduction,
                id: generateId(),
              }));
              setRows(deductionsWithId);
            }
          });

        } catch (error) {
          console.log("error: ", error);
        }
  
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    // const subscription = API.graphql(graphqlOperation(onCreateSFTPFile)).subscribe({
    const subscription = client.graphql({ query: onCreateSFTPFile }).subscribe({
      next: (result) => {

        const input = {
          input: {
            SubmissionDate: selectedDateRef.current.format("YYYY-MM-DD"),
          }
        };

        try {
          // API.graphql(graphqlOperation(getSFTPFile, input))
          client.graphql({ query: getSFTPFile, variables: input })
          .then((result) => {
            const retrievedDoc = result.data.getSFTPFile;
            setFileContent(retrievedDoc);
          });

        } catch (error) {
          console.log("error: ", error);
        }
  
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    // const subscription = API.graphql(graphqlOperation(onUpdateSFTPImportDate)).subscribe({
    const subscription = client.graphql({ query: onUpdateSFTPImportDate }).subscribe({

      next: (result) => {

        const sftpImportDate = result.value.data.onUpdateSFTPImportDate.SFTPImportDate;
        setDateFromSSM(dayjs(sftpImportDate));
        handleDateChange(dayjs(sftpImportDate));
  
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    selectedDateRef.current = newValue;

    const input = {
      input: {
        SubmissionDate: newValue.format("YYYY-MM-DD")
      }
    };

    // API.graphql(graphqlOperation(queryDeductionsByDate, input))
    client.graphql({ query: queryDeductionsByDate, variables: input })
    .then((result) => {
      const retrievedDeductions = result.data.queryDeductionsByDate;
      if (retrievedDeductions) {
        const deductionsWithId = retrievedDeductions.map((deduction) => ({
          ...deduction,
          id: generateId(),
        }));
        setRows(deductionsWithId);
      }
    });

    const input2 = {
        input: {
          SubmissionDate: newValue.format("YYYY-MM-DD")
        }
    };

    try {
      // API.graphql(graphqlOperation(getSFTPFile, input2))
      client.graphql({ query: getSFTPFile, variables: input2 })
      .then((result) => {
        const retrievedDoc = result.data.getSFTPFile;
        setFileContent(retrievedDoc);
      });

    } catch (error) {
      console.log("error: ", error);
    }
    
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  
  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    
    const deletedRow = rows.find((row) => row.id === id);
    const { EmployeeNumber } = deletedRow;

    const input = {
      input: {
        SubmissionDate: selectedDateRef.current.format("YYYY-MM-DD"),
        EmployeeNumber: EmployeeNumber
      }
    };

    try {
      // API.graphql(graphqlOperation(deleteDeduction, input));
      client.graphql({ query: deleteDeduction, variables: input })

    } catch (error) {
        // setAlertText(error.errors[0].message);
        // setOpen(true);
    }
  };
    
  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const processRowUpdate = async (newRow, oldRow) => {
    
    const updatedRow = { ...newRow };
    await validateRow(updatedRow);
    
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    if (newRow.EmployeeNumber === oldRow.EmployeeNumber || !oldRow.EmployeeNumber) {
      // Same Employee Numbers OR new row
    } else {
      const inputDelete = {
        input: {
          SubmissionDate: selectedDateRef.current.format("YYYY-MM-DD"),
          EmployeeNumber: oldRow.EmployeeNumber
        }
      };
  
      try {
        // API.graphql(graphqlOperation(deleteDeduction, inputDelete));
        client.graphql({ query: deleteDeduction, variables: inputDelete })
  
      } catch (error) {
          // setAlertText(error.errors[0].message);
          // setOpen(true);
      }
    }

    setSnackbar({ children: 'Deduction successfully saved', severity: 'success' });
    const { EmployeeNumber, PayrollPeriodEndDate, Amount } = updatedRow;

    const input = {
      input: {
        SubmissionDate: selectedDateRef.current.format("YYYY-MM-DD"),
        EmployeeNumber: EmployeeNumber,
        PayrollPeriodEndDate: PayrollPeriodEndDate,
        Amount: Amount,
      }
    };

    try {
      // API.graphql(graphqlOperation(createDeduction, input));
      client.graphql({ query: createDeduction, variables: input })

    } catch (error) {
    //     // setAlertText(error.errors[0].message);
    //     // setOpen(true);
    }

    return updatedRow;
  };

    
  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { 
      field: 'EmployeeNumber',
      headerName: 'Employee Number', 
      // type: 'number',
      width: 100,
      editable: true,
    },
    {
      field: 'PayrollPeriodEndDate',
      headerName: 'Payroll Period End Date',
      // type: 'date',
      width: 120,
      editable: true,
    },
    {
      field: 'Amount',
      headerName: 'Amount',
      type: 'number',
      width: 90,
      editable: true,
      valueFormatter: (params) => `$${Number(params.value).toFixed(2)}`,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;
  
    const handleClick = () => {
      const id = generateId();
      
      setRows((oldRows) => {
        return [
          ...(oldRows || []),
          { id, EmployeeNumber: '', PayrollPeriodEndDate: selectedDateRef.current.format("YYYY-MM-DD"), Amount: '', isNew: true },
        ];
      });
      
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'EmployeeNumber' },
      }));
    };

    return (
      <GridToolbarContainer>
        {selectedDate.toISOString() === dateFromSSM.toISOString() && (
          <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add Deduction
          </Button>
        )}
      </GridToolbarContainer>
    );
  }

  const handleKeyDownEvent = (params, event, details) => {
    // Handle the event logic
    if (rowModesModel[params.id] && rowModesModel[params.id].mode === "edit") {
      switch(event.key) {
        case "Escape":
          handleCancelClick(params.id)();
          break;
        case "Enter":
          handleSaveClick(params.id)();
          break;
        default:
          break;
      }
    }
  };

  const viewUpdatedDeductionsDataGrid = () => {
    if(rows){
      return (
        <Box
          sx={{
            // height: 500,
            // maxHeight: 800,
            width: '100%',
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
          }}
        >
          <br/>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={selectedDate}
              onChange={handleDateChange}
              slotProps={{
                actionBar: { actions: ["today", "accept"] },
              }}
            />
          </LocalizationProvider>
          <br />
          <br />
          <Box
            sx={{
              height: 500, // Set the desired height
              width: '100%',
              overflow: 'auto', // Enable vertical scroll
            }}
          >
          <DataGrid
            rows={rows}
            columns={columns}
            headerHeight={80}
            disableColumnMenu={true}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                textOverflow: "clip",
                whiteSpace: "break-spaces",
                lineHeight: 1
              }
            }}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            onCellKeyDown={handleKeyDownEvent}
            slots={{
              toolbar: EditToolbar,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            columnVisibilityModel={{
              actions: selectedDate.toISOString() === dateFromSSM.toISOString(),
              // PayrollPeriodEndDate: false
            }}
          />
          </Box>
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
          )}
        </Box>
      );
    }   
  }

  const viewSFTPFile = () => {
    if(fileContent.Body){
      return (
        <>
          <br/>
          <br/>
          <br/>
          <Typography variant="h6" component="h3">
            SFTP Import File
          </Typography>
          <Paper
            elevation={3}
            style={{ padding: '1rem' }}
            sx={{
              height: 500, // Set the desired height
              width: '100%',
              overflow: 'auto', // Enable vertical scroll
            }}
          >
            {fileContent && (
              <div>
                {fileContent.Body.split('\n').map((line, index) => (
                  <Typography
                    variant="body1"
                    component="div"
                    key={index}
                    style={{ whiteSpace: 'pre' }}
                  >
                    {line}
                  </Typography>
                ))}
              </div>
            )}
          </Paper>
        </>
      );
    }   
  }

    return (
        <>
        {/* <div>
          This is deductions
        </div> */}
        <Box >
            <Grid
                container
                spacing={2}
                justifyContent="space-evenly"
                alignItems="center"
            >
                <Grid item xs={12} sm={6}>
                    {viewUpdatedDeductionsDataGrid()}
                </Grid>
                <Grid item>
                    {viewSFTPFile()}
                </Grid>
            </Grid>
            <br/>
        </Box>
      </>
    );

}